<template>
    <div id="dc-reports">
        <div id="dc-header">
            <h3>Reports</h3>
            <!-- <b-button class="dc-button-primary" @click="onClickSendSMS">Send SMS</b-button> -->
        </div>

        <hr />

        <!-- Loading Spinner -->
        <div v-if="customersList.length == 0" class="dc-loading-spinner">
            <b-spinner variant="primary" label=""></b-spinner>
        </div>

        <!-- Table -->
        <v-client-table v-else id="dc-customers-table" :data="customersList" :columns="columns" :options="options">
            <div class="dc-actions-container" slot="actions" slot-scope="{ row }">
                <b-button class="dc-button-primary" @click="onClickButtonSendSingle(row)">Send</b-button>
            </div>
        </v-client-table>

        <!-- Modal - Report -->
        <b-modal id="dc-modal-report" ref="dc-modal-report" title="Send Report" size="lg" @hidden="onCloseModalReport" hide-footer>
            <div id="dc-report-header">
                <span class="dc-report-id">Id: {{ report.customerId }}</span>
                <span class="dc-report-name">{{ report.name }}</span>
                <b-form-group id="dc-report-emails">
                    <b-form-input v-model="report.emails" type="text" placeholder="Emails"></b-form-input>
                </b-form-group>
            </div>

            <div id="dc-report-date">
                <!-- Date - From -->
                <div id="dc-report-date-from">
                    <span>From:</span>
                    <datepicker class="dc-input" type="date" :minimumView="'day'" :maximumView="'month'" format="MMM dd, yyyy" placeholder="Select Date" @input="onInputSetDateFrom($event)"></datepicker>
                </div>

                <!-- Date - To -->
                <div id="dc-report-date-to">
                    <span>To:</span>
                    <datepicker class="dc-input" :minimumView="'day'" :maximumView="'month'" format="MMM dd, yyyy" placeholder="Select Date" @input="onInputSetDateTo($event)"></datepicker>
                </div>
                <b-button id="dc-btn-done" @click="onClickButtonGenerateReport">Generate Report</b-button>
            </div>
            <hr />

            <b-form-textarea id="dc-report-textarea" v-model="message" placeholder="Write message for Customer.." rows="3" max-rows="5"></b-form-textarea>
            <hr />

            <!-- Empty - Loading -->
            <div v-if="report.tickets.length === 0 && report.template === ''" id="dc-report-empty-loading">
                <span v-if="report.status === 'none'">No tickets</span>
                <b-spinner v-if="report.status === 'loading'" variant="success" label="Spinning"></b-spinner>
            </div>

            <div v-else id="dc-report-template-container">
                <!-- Template -->
                <div id="dc-report-template" v-html="report.template"></div>
                <hr :style="{ width: '100%', marginTop: '20px !important' }" />
                <b-button id="dc-btn-send-report-email" @click="onClickButtonSendEmailToCustomer">Send Email</b-button>
            </div>
        </b-modal>

        <!-- Modal - Sending -->
        <b-modal id="dc-modal-sending" ref="dc-modal-sending" title="Sending..." size="sm" centered hide-footer>
            <img src="../../assets/loading.gif" />
        </b-modal>
    </div>
</template>

<script>
import _ from "lodash";
import CustomerAPI from "./../customer/APICalls";
import EmailTemplateFile from "../../constant/EmailTemplate";
import TicketsAPICalls from "./../ticket/APICalls";
import APICalls from "./APICalls";
import Datepicker from "vuejs-datepicker";
import ejs from "ejs";
import escapeString from "js-string-escape";
import moment from "moment";
import util from "./../../util/util";

export default {
    name: "Reports",
    components: {
        Datepicker,
    },
    data() {
        return {
            customersList: [],
            customerTickets: [],
            columns: ["id", "name", "email", "truck", "quickbooksId", "actions"],
            options: {
                perPage: 200,
                orderBy: {
                    column: "name",
                    ascending: true,
                },
                headings: {
                    productsId: "Products",
                    quickbooksId: "QB Id",
                },
                templates: {},
            },
            report: { customerId: "", name: "", emails: "", remaining: "", dateFrom: "", dateTo: "", dateToAddedOne: "", tickets: [], template: "", status: "none" },
            message: "",
            alert: {
                dismissCountDown: 0,
                message: "Email sent success!",
            },
        };
    },
    methods: {
        closeModalAll() {
            this.$bvModal.hide("dc-modal-report");
            // this.$bvModal.hide("dc-modal-sales");
            // this.$bvModal.hide("dc-modal-send-all");
            // this.$bvModal.hide("dc-modal-generate-code");
            this.$bvModal.hide("dc-modal-sending");
            this.$bvModal.hide("dc-modal-report");
        },
        generateTicketsTemplateForCustomer(tickets, remaining, dateFrom, dateTo) {
            if (!this.report.dateFrom || !this.report.dateTo) return;

            let filteredTickets = [];

            // Format Timestamp and filter Tickets
            _.each(tickets, (ticket) => {
                ticket.createdAt = util.formatTimestamp(ticket.createdAt);

                // Filter Tickets
                let isDateIncluded = util.isDateIncluded(this.report.dateFrom, this.report.dateTo, ticket.createdAt);
                if (isDateIncluded) {
                    filteredTickets.push(ticket);
                }
            });

            filteredTickets = _.orderBy(filteredTickets, ["id"], ["desc"]);

            //
            let msg = this.message;
            msg = msg.replace(/\n/g, "<br>");
            msg = msg.replace(/"/g, "&quot;");
            msg = msg.replace(/'/g, "&#39;");
            msg = msg.replace(/\s/g, "&nbsp;");

            //
            let template = EmailTemplateFile.template;
            let result = ejs.render(template, {
                message: msg,
                tickets: filteredTickets,
                remaining: remaining,
                dateFrom: dateFrom,
                dateTo: dateTo,
            });
            result = escapeString(result);
            return result;
        },
        onClickButtonSendSingle(customer) {
            this.report.customerId = customer.id;
            this.report.name = customer.name;
            this.report.emails = customer.email;
            this.showModalReport();
        },
        onClickButtonGenerateReport() {
            this.getCustomerTickets();
        },
        onCloseModalReport() {
            this.message = "";
            this.report = {
                customerId: "",
                name: "",
                email: "",
                remaining: "",
                dateFrom: "",
                dateTo: "",
                dateToAddedOne: "",
                tickets: [],
                template: "",
                status: "none",
            };
        },
        onInputSetDateFrom(event) {
            this.report.dateFrom = moment(event);
        },
        onInputSetDateTo(event) {
            this.report.dateTo = moment(event);
        },
        setReportTickets() {
            this.report.tickets = this.customerTickets;
            let ticketsTemplate = this.generateTicketsTemplateForCustomer(this.report.tickets, 40, this.report.dateFrom, this.report.dateTo);
            this.report.template = ticketsTemplate;
        },
        showModalReport() {
            // this.closeModalAll();
            this.$refs["dc-modal-report"].show();
        },
        showModalSending() {
            this.$refs["dc-modal-sending"].show();
        },
        hideModalSending() {
            this.$refs["dc-modal-sending"].hide();
        },
        resetReportData() {
            this.report = { customerId: "", name: "", emails: "", remaining: "", dateFrom: "", dateTo: "", dateToAddedOne: "", tickets: [], template: "", status: "none" };
            this.message = "";
        },
        async onClickButtonSendEmailToCustomer() {
            this.showModalSending();
            this.alert.dismissCountDown = 0;

            let emailsArray = this.report.emails.split(",");

            // Send email
            for (let index in emailsArray) {
                let email = emailsArray[index];
                email = email.trim();
                try {
                    let response = await APICalls.sendEmail(this.report.name, email, this.report.template);
                    console.log(response);
                } catch (errResponse) {
                    console.log(errResponse);
                }
            }

            this.hideModalSending();
            this.alert.dismissCountDown = 60;
            this.resetReportData();
            this.closeModalAll();
        },
        async getCustomers() {
            try {
                let customers = await CustomerAPI.getCustomers();
                this.customersList = customers;
                this.smsCustomersList = customers;
            } catch (errResponse) {
                console.log(errResponse);
                this.$router.push({ path: "/login" });
            }
        },
        async getCustomerTickets() {
            if (!this.report.customerId) return;

            let tickets = await TicketsAPICalls.getTicketsForCustomer(this.report.customerId);
            this.customerTickets = tickets;
            this.setReportTickets();
        },
    },
    mounted() {
        this.getCustomers();
    },
};
</script>

<style lang="scss">
#dc-reports {
    #dc-header {
        background: transparent;
        box-shadow: none;
        display: flex;
    }

    .dc-icon-edit {
        width: 20px;
        height: 20px;
        color: black;
        cursor: pointer;
    }

    .dc-icon-delete {
        width: 20px;
        height: 20px;
        margin-left: 20px;
        color: #800000;
        cursor: pointer;
    }

    .dc-button-primary {
        width: 140px;
    }

    #dc-reports-table {
        table {
            tbody {
                tr {
                    td:nth-child(3) {
                        max-width: 200px;
                    }
                }
            }
        }
    }
}

// Modal - Report
#dc-modal-report {
    display: block;
    .modal-body {
        padding: 20px;
        #dc-report-header {
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
            span:nth-child(1) {
                flex: 0.5;
            }
            span:nth-child(2) {
                flex: 1.5;
            }
            #dc-report-emails {
                flex: 1.5;
                text-align: right;
            }
        }
        // Report - Date
        #dc-report-date {
            display: flex;
            align-items: center;
            margin: 20px 0px;
            font-weight: bold;
            #dc-report-date-from {
                display: flex;
                span {
                    font-size: 18px;
                }
                .dc-input {
                    padding-left: 10px;
                    input {
                        padding-left: 5px;
                        width: 140px;
                    }
                }
                .vdp-datepicker__calendar {
                    width: 340px;
                }
            }
            #dc-report-date-to {
                display: flex;
                margin-left: 30px;
                span {
                    font-size: 18px;
                }
                .dc-input {
                    padding-left: 10px;
                    input {
                        padding-left: 5px;
                        width: 140px;
                    }
                }
                .vdp-datepicker__calendar {
                    width: 340px;
                }
            }
            #dc-btn-done {
                margin-left: 50px;
            }
        }
        #dc-report-empty-loading {
            width: 100%;
            height: 380px;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                font-weight: bold;
                font-size: 20px;
            }
        }
        #dc-report-alert-email {
            background-color: #007bc4;
            color: #fff;
            width: 320px;
            position: absolute;
            opacity: 0.9;
        }
        #dc-report-template-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            #dc-report-template {
                width: 100%;
                display: flex;
                flex-direction: column;
                padding: 10px 0px;
            }
            #dc-btn-send-report-email {
                margin-top: 20px;
                background-color: #007bc4;
                width: 120px;
            }
        }
    }
}

// Modal - Sending
#dc-modal-sending {
    .modal-body {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
