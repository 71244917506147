import axios from "axios";

let sendEmail = function(name, email, info) {
    return new Promise((resolve, reject) => {
        let SendEmailApiUrl = "https://hb85rxwhif.execute-api.us-east-1.amazonaws.com/prod";

        axios
            .post(SendEmailApiUrl, {
                name,
                email,
                info,
            })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
};

export default { sendEmail };
